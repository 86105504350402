const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.fetchUsersOfCompany = function () {
  return axios.get(baseUrlNew + 'user', {
    headers: buildAuthHeaders()
  });
};

exports.fetchUsersListForScheduledReports = function () {
  return axios.get(baseUrlNew + 'user/for-report', {
    headers: buildAuthHeaders()
  });
};

exports.deleteUser = function (userId) {
  return axios.delete(baseUrlNew + `user/${userId}`, {
    headers: buildAuthHeaders()
  });
};

exports.deleteInvitation = function (userId) {
  return axios.delete(baseUrlNew + `user/invitation/${userId}`, {
    headers: buildAuthHeaders()
  });
};

exports.inviteUser = function (params) {
  return axios.post(baseUrlNew + `user/invite`, params, {
    headers: buildAuthHeaders()
  });
};

exports.updateUerRole = function (userId, params) {
  return axios.put(baseUrlNew + `user/${userId}/role`, params, {
    headers: buildAuthHeaders()
  });
};
