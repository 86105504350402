<template>
  <div id="createScheduledReportsPage" class="p-4 max-w-screen-2xl m-auto">
    <!-- Page Loader -->
    <div class="rounded-md overflow-hidden border" v-if="fetchingData" v-loading="true" style="min-height: 95vh; width: 100%"></div>

    <el-form v-if="!fetchingData" class="reportForm" :model="form" ref="reportForm" label-position="top">
      <!-- Header -->
      <div v-if="!fetchingData" class="px-4 pt-3 border rounded-md bg-white pb-2">
        <div class="mt-0 md:flex md:items-center md:justify-between">
          <div class="flex">
            <h2 class="text-2xl font-bold leading-7 text-gray-900">
              <router-link class="text-black" to="/scheduled-reports">
                <i class="el-icon-back text-black"></i>
              </router-link>
            </h2>
          </div>
          <div class="flex-1 min-w-0 pl-3">
            <div class="text-2xl font-bold leading-7 text-gray-900">{{ isEditMode ? 'Update' : 'Create' }} Scheduled Report</div>
            <a target="_blank" class="text-xs text-primary-600 hover:text-primary-700" href="http://help.growlytics.in/en/articles/5795013-scheduling-campaign-reports"> Learn how to create scheduled report <i class="el-icon-top-right"></i> </a>
          </div>
          <div class="flex-shrink-0 flex -mt-1">
            <el-button type="primary" v-if="!isEditMode" @click="onCreate" :loading="savingChanges">Create Report</el-button>
            <template v-else>
              <el-button type="primary" @click="onUpdate" :loading="savingChanges">Save Changes</el-button>
              <el-button type="danger" @click="showDeleteConfirm" :loading="savingChanges">Delete Report</el-button>
            </template>
          </div>
        </div>
      </div>

      <div class="rounded-md border overflow-hidden w-full mt-4">
        <!-- Report Name & Receipients  -->
        <div class="bg-white border-b">
          <el-row class="px-3 pt-3" :gutter="20">
            <el-col :span="8">
              <label>Report Name</label>
              <el-form-item>
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <label>Send Reports To</label>
              <el-form-item>
                <el-select multiple v-model="form.users" filterable size="big" placeholder="Select" style="width: 100%">
                  <el-option v-for="(user, index) in userList" :key="index" :label="user.first_name + ' ' + user.last_name" :value="user.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <!-- Report Timing Section  -->
        <div class="bg-white overflow-hidden">
          <!-- Timing Type -->
          <div class="px-3 pt-3 border-b border-t-gray-300">
            <label>How do you want to receive reports?</label>
            <div class="titleRadio mb-3" size="small">
              <el-radio label="daily" v-model="form.timing.type" size="small" border>Send Report Daily</el-radio>
              <el-radio label="weekly" v-model="form.timing.type" size="small" border>Send Report Weekly</el-radio>
              <el-radio label="monthly" v-model="form.timing.type" size="small" border>Send Report Monthly</el-radio>
            </div>
          </div>

          <div class="px-3 pt-3">
            <!-- Timing Type - daily  -->
            <template v-if="form.timing.type == 'daily'">
              <label>Send reports daily at</label>
              <el-form-item>
                <el-select style="width: 140px" v-model="form.timing.daily" size="small" filterable>
                  <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                </el-select>
                &nbsp;<span class="text-gray-600 text-xs"> ({{ $store.state.token.timezoneName }} Time)</span>
              </el-form-item>
            </template>

            <!-- Timing Type - weekly  -->
            <template v-if="form.timing.type == 'weekly'">
              <!-- Days Of Week -->
              <label>Select week days to receive reports</label>
              <el-form-item>
                <el-checkbox-group v-model="form.timing.weekly.weekDays" border>
                  <template v-for="(day, index) in ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']">
                    <el-checkbox :label="index" border size="mini" :key="index">{{ day.toUpperCase() }}</el-checkbox>
                  </template>
                </el-checkbox-group>
              </el-form-item>

              <!-- Time Of Day -->
              <label>Send reports on above week days at</label>
              <el-form-item>
                <el-select style="width: 140px" v-model="form.timing.daily" size="small" filterable>
                  <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                </el-select>
                &nbsp;<span class="text-gray-600 text-xs"> ({{ $store.state.token.timezoneName }} Time)</span>
              </el-form-item>
            </template>

            <!-- Timing Type - monthly  -->
            <template v-if="form.timing.type == 'monthly'">
              <!-- Days Of Week -->
              <label>Month days to receive reports</label>
              <el-form-item>
                <el-select multiple v-model="form.timing.monthly.monthDays" placeholder="Select" size="big" filterable style="width: 750px">
                  <el-option v-for="item in monthDays" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>

              <!-- Time Of Day -->
              <label>Send reports on above month days at</label>
              <el-form-item>
                <el-select style="width: 140px" v-model="form.timing.daily" size="small" filterable>
                  <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                </el-select>
                &nbsp;<span class="text-gray-600 text-xs"> ({{ $store.state.token.timezoneName }} Time)</span>
              </el-form-item>
            </template>
          </div>
        </div>
      </div>

      <!-- Report Components Section -->
      <div class="mt-8 mb-2">
        <div class="text-2xl font-bold mb-3">What all data you want in report?</div>

        <!-- Report Component List -->
        <div class="bg-white border rounded overflow-hidden mb-4 reportComponent" v-for="(reportComponent, componentIndex) in form.components" :key="componentIndex">
          <!-- Report Component Header -->
          <div class="min-w-full border-b border-b-gray-200 bg-gray-100">
            <span style="display: inline-block" class="px-3 py-1 leading-10 text-left text-base text-black tracking-wider">{{ reportComponent.label }} </span>
            <div class="float-right mt-3 mr-3" @click="form.components.splice(componentIndex, 1)">
              <el-button type="danger" size="small" icon="el-icon-delete" circle></el-button>
            </div>
            <div class="float-right mt-2 mr-4">
              <div class="text-xs leading-4 text-gray-600">Time-range for data</div>
              <el-select v-model="reportComponent.timeline" size="small">
                <el-option v-for="item in campaignOverviewTimingOptions" :key="item.value" :label="item.title" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="min-w-full -mt-2 px-3 mb-2 text-gray-500" style="font-size: 12px">
              {{ reportingTemplateDescriptions[reportComponent.type] }}
            </div>
          </div>

          <!-- Component Type Wise Rendering -->
          <div class="px-3 pt-3">
            <!-- Journey Summary Report Block -->
            <template v-if="reportComponent.type == 'journey-summary'">
              <!-- Journey Summary List Type -->
              <div>
                <label>Which all journeys you want to receive summary report?</label>
                <div class="mb-1">
                  <el-radio label="all" v-model="reportComponent.data.listType" size="mini" border>All Running Journeys</el-radio>
                  <el-radio label="specific" v-model="reportComponent.data.listType" size="mini" border>Specific Running Journeys</el-radio>
                </div>
              </div>

              <!-- Journey Summary: Specific Journey List -->
              <div v-if="reportComponent.data.listType == 'specific'">
                <label>Select Journeys (Only Running Journeys)</label>
                <el-form-item>
                  <el-select multiple v-model="reportComponent.data.list" filterable size="big" style="width: 100%">
                    <el-option v-for="(journey, index) in journeyList" :key="index" :label="journey.name" :value="journey.id"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>

            <!-- Journey Detailed Report Block -->
            <template v-if="reportComponent.type == 'journey-detail'">
              <!-- Journey Summary: Specific Journey List -->
              <label>Select Journeys To Receive Detailed Reports (Only Running Journeys)</label>
              <el-form-item>
                <el-select multiple v-model="reportComponent.data.list" filterable size="big" style="width: 100%">
                  <el-option v-for="(journey, index) in journeyList" :key="index" :label="journey.name" :value="journey.id"> </el-option>
                </el-select>
              </el-form-item>
            </template>

            <!-- Campaign Summary Report Block -->
            <template v-if="reportComponent.type == 'campaign-summary'">
              <!-- Journey Summary List Type -->
              <div>
                <label>Which all campaigns you want to receive summary report?</label>
                <div class="mb-1">
                  <el-radio label="all" v-model="reportComponent.data.listType" size="mini" border>All Running Campaigns</el-radio>
                  <el-radio label="specific" v-model="reportComponent.data.listType" size="mini" border>Specific Running Campaigns</el-radio>
                </div>
              </div>

              <!-- Journey Summary: Specific Journey List -->
              <div v-if="reportComponent.data.listType == 'specific'">
                <label>Select Campaigns (Only Journey Campaigns, For One-Time Campaigns, use all reports option).</label>
                <el-form-item>
                  <el-select multiple v-model="reportComponent.data.list" filterable size="big" style="width: 100%">
                    <el-option v-for="(campaign, index) in campaignList" :key="index" :label="campaign.name" :value="campaign.id">
                      <span style="float: left">{{ campaign.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ campaign.channel.toUpperCase() }} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>

            <!-- Delivery Summary Report Block -->
            <template v-if="reportComponent.type == 'delivery'">
              <label>Select channels for reporting</label>
              <el-form-item>
                <el-checkbox-group v-model="reportComponent.data.list" border>
                  <template v-for="(channelName, channel) in campaignChannelDisplayNames">
                    <el-checkbox :label="channel" border size="mini" :key="channel">{{ channelName }}</el-checkbox>
                  </template>
                </el-checkbox-group>
              </el-form-item>
            </template>
          </div>
        </div>

        <!-- Add Report Component Button -->
        <el-button type="primary" round plain @click="showDrawer = true">Add More Data In Report</el-button>
      </div>
    </el-form>

    <!-- Component Selector -->
    <el-drawer title="Add Report Data" custom-class="scheduledReportSelectorPopup relative" :visible.sync="showDrawer" direction="btt">
      <div class="p-4">
        <div class="text-lg">What kind of data you want to add in report?</div>
        <div v-for="(template, index) in reportingTemplates" :key="index">
          <div class="my-3 bg-white border rounded-lg shadow-sm px-3 py-3 cursor-pointer" @click="selectorPopup.selectedTemplateType = template.type" :class="{ selectedTemplate: selectorPopup.selectedTemplateType == template.type }">
            <div class="text-base">{{ template.label }}</div>
            <div class="pt-2 text-xs text-gray-500">{{ reportingTemplateDescriptions[template.type] }}</div>
          </div>
        </div>
      </div>
      <div class="p-3 px-4 border-t">
        <div class="float-left mb-3">
          <div class="text-xs leading-4 text-gray-600">Time-range for data</div>
          <el-select v-model="selectorPopup.timeline" size="small">
            <el-option v-for="item in campaignOverviewTimingOptions" :key="item.value" :label="item.title" :value="item.value"> </el-option>
          </el-select>
        </div>
        <div class="float-right mt-2 mr-0">
          <el-button type="primary" @click="addDataToReport">Add Data To Report </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<style lang="scss" src="./createScheduledreports.scss"></style>

<script>
import createScheduledReportsComponent from './createScheduledReportsComponent';
export default createScheduledReportsComponent;
</script>
