import journeyService from '@/services/journey';
import userService from '@/services/user';
import campaignService from '@/services/campaign';
import scheduledReportService from '@/services/scheduledReport';
import campaignMixin from '@/mixins/campaignMixin';

import * as _ from 'lodash';

export default {
  name: 'createScheduledReport',
  mixins: [campaignMixin],
  components: {},
  data() {
    return {
      fetchingData: false,
      savingChanges: false,

      isEditMode: false,
      reportId: null,
      campaignOverviewTimingOptions: [
        {
          title: 'Current Week',
          value: 'this-week'
        },
        {
          value: 'last-week',
          title: 'Last Week'
        },
        {
          value: 'this-month',
          title: 'Current Month'
        },
        {
          value: 'last-month',
          title: 'Last Month'
        },
        {
          title: 'Last 7 Days',
          value: 'last-7'
        },
        {
          title: 'Last 15 Days',
          value: 'last-15'
        },
        {
          title: 'Last 30 Days',
          value: 'last-30'
        },
        {
          title: 'Last 45 Days',
          value: 'last-45'
        },
        {
          title: 'Last 60 Days',
          value: 'last-60'
        },
        {
          title: 'Last 90 Days',
          value: 'last-90'
        }
      ],

      form: {
        name: '',
        users: [],

        timing: {
          type: 'daily',
          daily: 8,
          weekly: {
            weekDays: [0],
            time: 8
          },
          monthly: {
            monthDays: ['last'],
            time: 8
          }
        },

        components: [
          {
            type: 'delivery',
            label: 'Delivery Summary - Channel Wise',
            timeline: 'last-7',
            data: {
              list: ['email', 'sms', 'whatsApp', 'webPush', 'mobilePush', 'inApp']
            }
          },
          {
            type: 'campaign-summary',
            label: 'Campaign Summary',
            timeline: 'last-7',
            data: {
              listType: 'all',
              list: []
            }
          },
          {
            type: 'journey-summary',
            label: 'Journey Summary',
            timeline: 'last-7',
            data: {
              listType: 'all',
              list: []
            }
          }
        ]
      },

      monthDays: [],
      reportingTemplates: [
        {
          type: 'delivery',
          label: 'Delivery Summary - Channel Wise',
          timeline: 'last-7',
          data: {
            list: ['email', 'sms', 'whatsApp', 'webPush', 'mobilePush', 'inApp']
          }
        },
        {
          type: 'campaign-summary',
          label: 'Campaign Summary',
          timeline: 'last-7',
          data: {
            listType: 'all',
            list: []
          }
        },
        {
          type: 'journey-summary',
          label: 'Journey Summary',
          timeline: 'last-7',
          data: {
            listType: 'all',
            list: []
          }
        }
        // {
        //   type: 'journey-detail',
        //   label: 'Journey Detailed Summary',
        //   timeline: 'last-7',
        //   data: {
        //     list: []
        //   }
        // },
      ],
      reportingTemplateDescriptions: {
        'journey-summary': `High level summary of journeys. Includes messages being sent and conversions.`,
        'journey-detail': `Detailed summary of journeys. Includes journey's high level summary plus performance of all journey's campaigns.`,
        'campaign-summary': `High level summary of campaigns. Includes messages being sent and conversions.`,
        delivery: `Get channel wise report and performance of messages being sent. Howmany are being send, failed etc.`
      },

      userList: null,
      journeyList: null,
      campaignList: null,

      // Drawer Fields
      showDrawer: false,
      selectorPopup: {
        selectedTemplateType: 'journey-summary',
        timeline: 'last-7'
      }
    };
  },
  methods: {
    prepareMonthDays() {
      this.monthDays = [
        { label: 'Last Day Of Month', value: 'last' },
        { label: 'First Day Of Month', value: 'first' }
      ];
      for (let i = 0; i < 30; i++) {
        this.monthDays.push({
          label: 'Day ' + (i + 1),
          value: i
        });
      }
    },

    addDataToReport() {
      let clone = _.find(this.reportingTemplates, (template) => {
        return template.type == this.selectorPopup.selectedTemplateType;
      });
      clone = JSON.parse(JSON.stringify(clone));
      clone.timeline = this.selectorPopup.timeline;
      this.form.components.push(clone);
      this.showDrawer = false;
    },

    //#region -------------- Start: Fetch Data For Page ------------------

    async fetchJourneyList() {
      try {
        let result = await journeyService.fetchJourneyListForScheduledReports(this);
        this.journeyList = result.data;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong.');
      }
    },

    async fetchCampaignList() {
      try {
        let result = await campaignService.fetchCampaignListForScheduledReports(this);
        this.campaignList = result.data;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong. Please contact support.');
      }
    },

    async fetchUserList() {
      try {
        let result = await userService.fetchUsersListForScheduledReports(this);
        this.userList = result.data;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong. Please contact support.');
      }
    },

    //#endregion ----------- Start: Fetch Data For Page ------------------

    //#region -------------- Start: Create & Update ------------------

    async fetchDetailsForEdit(reportId) {
      this.isEditMode = true;
      this.reportId = reportId;
      this.fetchingData = true;
      try {
        let result = await scheduledReportService.getDetails(reportId, this);
        console.log('fetch data result', result.data);
        if (!result.data) {
          // Show 404
          this.$router.replace('/404');
          return;
        }

        this.form = result.data;

        this.fetchingData = false;
      } catch (err) {
        this.fetchingData = false;
        this.errorToast('Something went wrong, please contact support team.');
        console.error(err);
      }
    },

    async validateForm() {
      // Name
      if (!this.form.name) {
        this.warningToast('Report Name is required.');
        return false;
      } else if (this.form.name.length > 200) {
        this.warningToast('Report Name can not exceed 200 characters.');
        return false;
      }

      // Users
      if (!this.form.users || this.form.users.length == 0) {
        this.warningToast('Please select report users, to whom reports will be sent.');
        return false;
      }

      // Timer - Weekly
      if (this.form.timing.type == 'weekly' && this.form.timing.weekly.weekDays.length == 0) {
        this.warningToast('Please select week days to receive reports.');
        return false;
      }

      // Timer - Monthly
      if (this.form.timing.type == 'monthly' && this.form.timing.monthly.monthDays.length == 0) {
        this.warningToast('Please select month days to receive reports.');
        return false;
      }

      return true;
    },

    async onCreate() {
      try {
        let isValid = await this.validateForm();
        if (!isValid) return;

        // createScheduledReport
        this.savingChanges = true;
        let params = this.form;
        await scheduledReportService.createScheduledReport(params, this);

        this.successToast('Report Created.');
        this.$router.replace('/scheduled-reports');
        this.savingChanges = false;
      } catch (err) {
        this.savingChanges = false;
        console.error('Failed to create scheduled report.', err);
        this.errorToast(`Somethin's not right. Please contact support.`);
      }
    },

    async onUpdate() {
      try {
        let isValid = await this.validateForm();
        if (!isValid) return;

        // createScheduledReport
        this.savingChanges = true;
        await scheduledReportService.updateScheduledReport(this.reportId, this.form, this);
        this.successToast('Changes Saved.');
        this.savingChanges = false;
      } catch (err) {
        this.savingChanges = false;
        console.error('Failed to create scheduled report.', err);
        this.errorToast(`Somethin's not right. Please contact support.`);
      }
    },

    async showDeleteConfirm() {
      this.$swal({
        title: 'Delete Scheduled Report?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Delete Report'
      }).then(() => {
        this.deleteReport();
      });
    },

    async deleteReport() {
      try {
        this.savingChanges = true;
        let result = await scheduledReportService.deleteScheduledReport(this.reportId, this);
        if (!result.data.success) {
          this.errorToast('Something went wrong, please contact support team.');
        } else {
          this.$router.replace('/scheduled-reports');
          this.successToast('Report Deleted.');
        }
        this.savingChanges = false;
      } catch (err) {
        this.savingChanges = false;
        console.error(err);
        this.errorToast('Something went wrong, please contact support team.');
      }
    }

    //#endregion  -------------- End: Create & Update ------------------
  },

  async mounted() {
    this.prepareMonthDays();
    this.fetchJourneyList();
    this.fetchCampaignList();
    this.fetchUserList();

    if (this.$route.params.id) {
      this.fetchDetailsForEdit(this.$route.params.id);
    }
  }
};
