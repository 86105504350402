const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.fetchScheduledReportList = function (params) {
  return axios.post(baseUrlNew + `scheduled-report`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(baseUrlNew + `scheduled-report/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.createScheduledReport = function (params) {
  return axios.post(baseUrlNew + 'scheduled-report/create', params, {
    headers: buildAuthHeaders()
  });
};

exports.updateScheduledReport = function (id, params) {
  return axios.put(baseUrlNew + `scheduled-report/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteScheduledReport = function (id) {
  return axios.delete(baseUrlNew + `scheduled-report/${id}`, {
    headers: buildAuthHeaders()
  });
};
